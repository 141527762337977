:root {
  //font-families
  --base-font-family: "Moscow Sans", sans-serif;

  //font-sizes
  --text-size: 1rem;
  --h1-size: 2rem;
  --h3-size: 1.2rem;

  //line-heights
  --text-line-height: 1.11;
  --h1-line-heihgt: 1.16;
  --h3-line-height: 1.16;

  //colors
  --base-color: #EB725A;
  --blue-color: #445477;
  --dark-blue-color: #0E1856;
  --deep-blue-color: #192738;
  --gray3-color: #828282;

  //shadows
  --base-shadow: 0px 10px 15px rgba(17, 16, 77, 0.05);

  //border-radiuses
  --base-border-radius: 5px;

  //offsets
  --content-padding: 20px;
  --input-padding: 16px;
  --formSubmitButton: 40px;

  //widths
  --content-max-width: 1400px;
}

* {
  margin: 0;
  padding: 0;
}

input, textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

html {
  font-size: 18px;
  line-height: var(--text-line-height);
  color: var(--deep-blue-color);
  //overflow-x: hidden;
}

body {
  font-family: var(--base-font-family);
  //overflow-x: hidden;
}

h1 {
  font-size: var(--h1-size);
  line-height: var(--h1-line-heihgt);
}

h3 {
  font-size: var(--h3-size);
  line-height: var(--h3-line-height);
}

mark {
  background: none;
  color: var(--base-color);
  font-weight: 600;
}

button {
  background: none;
  border: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  font-weight: 600;

  &:focus {
    outline: none;
  }
}

.content {
  margin: 0 auto;
  padding: 0 var(--content-padding);
  max-width: var(--content-max-width);
  box-sizing: border-box;
}

.block {
  margin-top: 40px;
}

.formErrorMessage {
  margin-top: 3px;
  color: red;
  font-size: 0.77rem;
}

@media (min-width: 768px) {
  :root {
    --h1-size: 2.5rem;
    --h3-size: 1.5rem;
    --content-padding: 40px;
  }
}


@media (min-width: 900px) {
  :root {
    --content-padding: 60px;
    --formSubmitButton: 60px;
  }
}

@media (min-width: 1200px) {
  :root {
    --h1-size: 4rem;
    --h3-size: 2rem;
    --content-padding: 80px;
    --formSubmitButton: 80px;
  }
}


